$primary-light: #F4C42F;
$primary-white: #fff;

.jbd-header {
    font-size: 2em;
}

@media screen and (min-width: 1500px) { 
    .wrapper-main {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: row;
        z-index: 0;
    }
    .wrapper {
        width: 100%;
        height: 100%;
    }
    
    .left-container-works {
        width: 24%;
        background-color: #F4C42F;
    }
    .my-name-works {
        font-size: 2.5em;
        font-weight: 600;
        margin: 20px 0 0 40px;
    }

    .link-text {
        color: #D26442;
    }

    .name-mayer {
        border-bottom: solid 7px #171C46;
    }
    .float-dark-box{
        position: absolute;
        bottom: 15%;
        left: 5%;
        background-color: #171C46;
        width: 22px;
        height: 22px;
    }
    
    .right-container-works {
        width: 76%;
        display: flex;
        position: relative;
        background-color: #171C46;
    }
    
    .navigation-works {
        // flex-grow: 1;
        position: absolute;
        right: 0;
        border-style: solid;
        border-width: 0  0  0 1px;
        border-color: #F4C42F;
        padding-left: 20px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.2em;
        margin: 0 20px; 
        height: 100%;
    }
    
    .main-content {
        flex-grow: 12;
        display: flex;
    }
    
    .main-empty- {
        flex-grow: 45;
        position: relative;
    }
    
    .my-work {
        font-size: 1.3em;
        opacity: 0.9;
    }
    .float-dark-light {
        position: relative;
        top: 20%;
        left: 30%;
        background-color: #F4C42F;
        width: 22px;
        height: 22px;
        z-index: 2;
    }

    .float-white-box {
        position: absolute;
        background-color: #fff;
        width: 22px;
        height: 22px;
        right: 35%;
        z-index: 2;
    }
    
    .float-dark-primary {
        position: relative;
        top: 5%;
        right: 10%;
        background-color: #D26442;
        width: 22px;
        height: 22px;
    }
    .main-text {
        flex-grow: 25;
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
    }
    .text-syle {
        font-size: larger;
        color: #F4C42F;
    }
    .text-paragraph {
        margin: 4px 0;
    }
    .all-works {
        display: flex;
        flex-direction: column; 
    }
    .each-work {
        display: flex;
        justify-content: space-between;
        &:hover { 
            opacity: 0.7;
        }
    }
    .work-image-wrapper {
       img {
            height: 125px;
            width: 200px;
       }
    }
    .worker-separator {
        height: 3.5px;
        width: 100%;
        margin: 20px 0;
        background-color: #D26442;
    }
    .main-work-text {
        font-size: 2em;
        font-weight: 900;
        margin: 10px 0;
        color: white;
    }
    .secondary-work-text {
        /*font-size: 1.3em;
        color: #F5C509;
        width: 20em;*/
        margin-top: 12px;
        font-size: 1.15em;
        color: #F5C509;
        width: 26em;
    }
    .work-modal {
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba($color: #7e7e7e, $alpha: 0.6);
        z-index: 4;
        // filter: blur(8px);
        // -webkit-filter: blur(8px);
    }
    .left-modal {
        height: 100vh;
        width: 100%;
    }
    .blurred-modal {
        position: relative;
        width: 20%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba($color: #7e7e7e, $alpha: 0.6);
        filter: blur(8px);
        -webkit-filter: blur(8px);
    }
    .modal-main {
        position: fixed;
        right: -100%;
        background: white;
        width: 80%;
        height: 100%;
        top: 0;
        transition: all 1s;
    }
    .modal-main.is-modal-open {
        right:0;
    }
    .close-modal {
        margin: 20px 20px;
        display: none;
        font-size: 1.3em;
    }

    // works section
    .top-content {
        position: fixed;
        color: white;
        top: 10px;
    }
    
    .modal-section {
        // height: 100vh;
        padding: 40px;
    }
    .first-content {
        // height: 45%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #171C46;
        color: white;
        padding: 40px;
        opacity: 0.9;
        .main-work-text {
            font-size: 2.5em;
        }
    }
    .second-content {
        // height: 55%;
        display: flex;
        /*align-items: center;*/
        justify-content: space-between;
        color: #171C46;
        padding: 40px;
        padding-bottom: 15px;
        p, ul {
            max-width: 500px;
            margin-top: 0px;
        }
    }
    .modal-img-one {
        img {
            width: 450px;
        }
    }
    .modal-img-three {
        img {
            width: 450px;
        }
    }
    .double-images-wrapper {
        display: flex;
        flex-direction: column;
    }
    .modal-small-img {
        margin-bottom: -5px;
        /*margin-top: 15px;*/
        img {
            width: 450px;
            &.red {
                border: 3px solid #C07676;
            }
            &.green {
                border: 3px solid #8CC177;
            }
        }
    }
    .work-details {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        height: 100vh;
    }
    .all-caps {
        text-transform: uppercase;
    }
    .modal-justify-left {
        display: flex;
        flex-direction: column;
    }
    .works-type {
        margin-top: 40px;
    }

    .news-format {
        display: flex;
    }
    .news-format-left {
        margin-left: 10px;
        width: 450px;

    }
    .news-format-right {
        margin-left: 40px;
        color: #D26442;
        p {
            margin-top: 0px;
            position: relative;
            bottom: 10px;
        }
    }
    .quoted {
        font-size: 3em;
        position: relative;
        top: 10px;
        color: #D26442;
    }
    .news-sub-heading {
        font-weight: 600;
        margin-top: 0px;
    }
    .news-heading {
        font-weight: 900;
        margin: .5em 0;
    }
    .no-dec {
        text-decoration: none;
    }
    .no-dec:link {
        color: none;
    }
    .no-dec:active {
        color: white;
    }
    .no-dec:visited {
        color: white;
    }
    .emp-text {
        color: #F4C42F;
        font-size: 4.5em;
        margin: 20px 0;
    }
    .scroable-container {
        height: 500px;
        overflow-y: auto
    }
    .scroable-container::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
    }
      
    .scroable-container::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(151, 151, 151, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
    .text-container-works {
        font-size: 1em;
        flex-grow: 6;
        opacity: 0;
        transform: translateY(-20%);
        transition: opacity 2.5s ease-in;
        transition-delay: .5s;
        width: 85%;
        margin-right: 15%;
    }
    
    .main-empty-2 {
        flex-grow: 15;
    }
    
    .floating-profile-work {
        position: absolute;
        left: 12%;
        top:25%;
        opacity: 1;
        width: 28%;
        height: 50%;
        z-index: 2;
    }
    
    .floating-profile-work .profile-image-container {
        position: absolute;
        width: 350px;
        height: 350px;
        z-index: 2;
    }
    
    .image-prof-works {
        width: 100%;
    }
    
    .floating-profile-work .profile-image-blank {
        position: relative;
        background-color: #D26442;
        margin-top: 10%;
        margin-left: 10%;
        height: 378px;
        width: 350px;
        z-index: -1;
        border: 3.5px solid #171c46;
    }

    .floating-profile-work.is-loaded {
        top: 25%;
        left: 12%;
        opacity: 1;
    }

    .nav-link:link {
        /*opacity: 0.8;*/
        font-size: 1.3rem;
        line-height: 1.3rem;
        text-decoration: none;
    }

    .nav-link:hover {
        padding-bottom: 2px;
        border-bottom: 1px solid #fff;
        opacity: 1;
        transition: all 0.25;
      }

    /*.section-intro {
        width: 512px;
    }*/

    .all-blue {
        background-color: #171C46;
    }

   /* .left-to-full {
        margin-left: 20px;  
    }*/
    .bottom-div {
        display: flex;
        justify-content: space-between;
        opacity: 0.9;
    }
    .bottom-content {
        width: 48%;
    }
    .contains-colored-background {
        padding: 0px;
        .modal-justify-left, .all-blue-background, .left-to-full, .feedback-box  {
            padding: 40px !important;
        }
    }
}
@media screen and (min-width: 1200px) and (max-width: 1325px) {
    .all-works {
        font-size: 0.75em;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1499px) { 
    .set-up-profile {
        left: 14%;
        top: -15%;
        opacity: 0;
    }
    .animate-from-about {
        top: 0%;
        left: 0%;
        opacity: 1;
        transition: all 1.2s ease-in;
    
    }

    .wrapper-main {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: row;
        z-index: 0;
    }
    .wrapper {
        width: 100%;
        height: 100%;
    }
    
    .left-container-works {
        width: 24%;
        background-color: #F4C42F;
        height: 100vh;
    }

    .my-name-works {
        font-size: 2.5em;
        font-weight: 600;
        margin: 20px 0 0 40px;
    }
    .text-container-works {
        flex-grow: 4;
        opacity: 0;
        transform: translateY(-20%);
        transition: opacity 2.5s ease-in;
        transition-delay: .5s;
        width: 85%;
        margin-right: 5%;
    }

    .link-text {
        color: #D26442;
    }

    .name-mayer {
        border-bottom: solid 7px #171C46;
    }
    .float-dark-box{
        position: absolute;
        bottom: 15%;
        left: 5%;
        background-color: #171C46;
        width: 22px;
        height: 22px;
    }
    
    .right-container-works {
        width: 76%;
        display: flex;
        position: relative;
        background-color: #171C46;
    }
    
    .navigation-works {
        // flex-grow: 1;
        color: white;
        border-width: 1px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.2em; 
        margin: 0 20px;
        right: 0;
        height: 100%;
        border-style: solid;
        border-width: 0  0  0 1px;
        border-color: #F4C42F;
        border-width: 0  0  0 1px;
        padding: 0 0 0 20px;
        position: absolute;
    }
    
    .main-content {
        flex-grow: 12;
        // border-style: solid;
        // border-color: #F4C42F;
        // border-width: 0 1px 0 0;
        display: flex;
    }
    
    .main-empty- {
        flex-grow: 40;
    }
    
    .my-work {
        font-size: 1.3em;
        opacity: 0.9;
    }
    .float-dark-light {
        position: relative;
        top: 20%;
        left: 30%;
        background-color: #F4C42F;
        width: 22px;
        height: 22px;
    }

    
    .float-dark-primary {
        position: absolute;
        top: 5%;
        right: 10%;
        background-color: #D26442;
        width: 22px;
        height: 22px;
        z-index: 2;
    }
    
    .main-text {
        flex-grow: 20;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: left;
    
    }
    
    .text-syle {
        font-size: larger;
        color: #F4C42F;
    }
    
    .text-paragraph {
        margin: 4px 0;
    }

    .all-works {
        display: flex;
        flex-direction: column;
    }

    .each-work {
        display: flex;
        justify-content: space-between;
        &:hover { 
            opacity: 0.7;
        }
    }
    .work-image-wrapper {
         img {
            height: 125px;
            width: 200px;
        }
    }
    .worker-separator {
        height: 3.5px;
        width: 100%;
        margin: 20px 0;
        background-color: #D26442;
    }
    .main-work-text {
        font-size: 2em;
        font-weight: 900;
        margin: 10px 0;
        color: white;
    }
    .secondary-work-text {
        /*font-size: 1.3em;
        color: #F5C509;
        width: 20em;*/
        margin-top: 12px;
        font-size: 1.15em;
        color: #F5C509;
        width: 26em;
    }
    .work-modal {
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba($color: #7e7e7e, $alpha: 0.6);
        z-index: 4;
        // filter: blur(8px);
        // -webkit-filter: blur(8px);
    }
    .left-modal {
        height: 100vh;
        width: 100%;
    }
    .blurred-modal {
        position: relative;
        width: 20%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba($color: #7e7e7e, $alpha: 0.6);
        filter: blur(8px);
        -webkit-filter: blur(8px);
    }
    .modal-main {
        position: fixed;
        right: -100%;
        background: white;
        width: 80%;
        height: 100%;
        top: 0;
        transition: right 0.5s;
    }
    .modal-main.is-modal-open {
        right:0;
    }

    .close-modal {
        margin: 20px 20px;
        display: none;
    }


    // works section
    .top-content {
        position: fixed;
        color: white;
        top: 10px;
    }
    
    .modal-section {
        // height: 100vh;
        padding: 40px;
    }
    .first-content {
        // height: 45%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #171C46;
        color: white;
        padding: 40px;
        .main-work-text {
            font-size: 50px;
        }
    }
    .modal-img-one {
        img {
            width: 450px;
        }
    }

    .modal-img-three {
        img {
            width: 450px;
        }
    }

    .double-images-wrapper {
        display: flex;
        flex-direction: column;
    }

    .scrollable-container {
        padding-right: 18px;
    }

    .modal-small-img {
        margin-bottom: -5px;
        /*margin-top: 15px;*/
        img {
            width: 450px;
            &.red {
                border: 3px solid #C07676;
            }
            &.green {
                border: 3px solid #8CC177;
            }
        }
    }
    .work-details {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        height: 100vh;
    }
    .all-caps {
        text-transform: uppercase;
    }
    .modal-justify-left {
        display: flex;
        flex-direction: column;
    }
    .works-type {
        margin-top: 40px;
        span {
            font-weight: 600;
        }
    }

    .news-format {
        display: flex;
    }
    .news-format-left {
        margin-left: 10px;
        width: 450px;
    }
    .news-format-right {
        margin-left: 40px;
        color: #D26442;
        p {
            margin-top: 0px;
            position: relative;
            bottom: 10px;
        }
    }
    .quoted {
        font-size: 3em;
        position: relative;
        top: 10px;
        color: #D26442;
    }

    .news-sub-heading {
        font-weight: 600;
        margin-top: 0px;
    }
    .news-heading {
        font-weight: 900;
        margin: .5em 0;
    }
    .no-dec {
        text-decoration: none;
    }
    .no-dec:link {
        color: none;
    }
    .no-dec:active {
        color: white;
    }
    .no-dec:visited {
        color: white;
    }
    .emp-text {
        color: #F4C42F;
        font-size: 4.5em;
        margin: 20px 0;
    }
    
    .main-empty-2 {
        flex-grow: 6;
    }
    
    .floating-profile-work {
        position: absolute;
        left: 10%;
        top:25%;
        width: 28%;
        height: 50%;
        z-index: 2
    }
    
    .floating-profile-work .profile-image-container {
        position: absolute;
        width: 325px;
        height: 325px;
        z-index: 2;
    }
    
    .image-prof {
        width: 100%;
    }
    
    .floating-profile-work .profile-image-blank {
        position: relative;
        background-color: #D26442;
        margin-top: 10%;
        margin-left: 10%;
        height: 350px;
        width: 325px;
        z-index: -1;
        border: 3.5px solid #171c46;
    }

    .nav-link:hover {
        padding-bottom: 2px;
        border-bottom: 1px solid #fff;
        opacity: 1;
        transition: all 0.25;
      }

      .second-content {
        display: flex;
        /*align-items: center;*/
        justify-content: space-between;
        color: #171C46;
        padding: 40px;
        /*padding-bottom: 15px;*/
        p, ul {
            max-width: 500px;
            margin-top: 0px;
        }
        .news-sub-heading {
            margin-bottom: 10px;
            /*margin-top: 25px;*/
        }
    }

   /* .left-to-full {
        margin-left: 20px;
    }*/
    .grey-boxes {
        display: flex;
        justify-content: space-between;
    }
    .grey-box {
        img {
            width: 100%;
        }
    }
    .empty-box {
        width: 48%;
        height: 220px;
        position: relative;
    }
    .box-left-red {
        width: 48%;
    }
    
    .box-right-green{
        width: 48%;
    }
    .dark-section {
        display: flex;
        margin: 10px 0;
        justify-content: space-between;
    }
    .container-left{
        width: 48%;
    }
    .bigger-container {
        width: 48%;
        position: relative;
        margin-top: 30px;
    }
    .bottom-div {
        display: flex;
        justify-content: space-between;
    }
    .bottom-content {
        width: 48%;
    }
    /*.text {
        width: 60%;
    }*/
    .contains-colored-background {
        padding: 0px;
        .modal-justify-left, .all-blue-background, .left-to-full, .feedback-box  {
            padding: 40px !important;
        }
    }
}

@media screen and (min-width: 1324px) {
    .grey-boxes {
        display: flex;
        justify-content: space-between;
    }
    .grey-box {
        img {
            width: 100%;
        }
    }
    .empty-box {
        width: 48%;
        height: 220px;
        position: relative;
    }

    .wrapper {
        width: 100%;
        height: 100%;
    }
    .floating-profile .profile-image-container {
        position: absolute;
        width: 325px;
        height: 325px;
        z-index: 2;
    }
    
    .floating-profile .profile-image-blank {
        position: relative;
        background-color: #D26442;
        margin-top: 10%;
        margin-left: 10%;
        height: 350px;
        width: 325px;
        z-index: -1;
    }
    /*.left-to-full {
        margin-left: 20px;
    }*/
    .box-left-red {
        width: 48%;
    }
    
    .box-right-green{
        width: 48%;
    }
    .dark-section {
        display: flex;
        margin: 10px 0;
        justify-content: space-between;
    }
    .container-left{
        width: 48%;
    }
    .bigger-container {
        margin-top: 30px;
        width: 48%;
        position: relative;
    }
    .bottom-div {
        display: flex;
        justify-content: space-between;
    }
    .bottom-content {
        width: 48%;
    }
   /* .text {
        width: 60%;
    }*/
      .second-content {
        display: flex;
        /*align-items: center;*/
        justify-content: space-between;
        color: #171C46;
        padding: 40px;
        /*padding-bottom: 15px;*/
        p {
            max-width: 500px;
        }
    }
}

@media screen and (max-width: 1199px) {
  // go to top
    .go-to-top {
        position: absolute;
        bottom: 20px;
        right: 20px;
        cursor: pointer;
        z-index: 2;
        color: #D26442;
    }

    // grey boxes
    .empty-box {
        width: 100%;
        height: 220px;
        position: relative;
    }
    
    .box-left-red {
        width: 100%;
        margin-bottom: 25px;
    }
    
    .box-right-green{
        width: 100%;
    }

    .dark-section {
        display: flex;
        flex-direction: column;
        margin: 10px 20px;
    }

    .container-left{
        width: 100%;
    }
    .bigger-container {
        width: 100%;
        position: relative;
    }
    .bottom-div {
        display: flex;
        flex-direction: column;
    }
    .bottom-content {
        width: 100%;
    }

    // main wrapper
    .wrapper {
        width: 100%;
        height: 100%;
    }
    .wrapper-main {
        display: flex;
        width: 100%;
        flex-direction: column;
        z-index: 0;
        height: 100%;
    } 
    .left-container-works {
        background-color: #F4C42F;
        height: 70px;
    }

    .text-container {
        width: 100%;
        font-size: 1em;
        color: white;
    }

    .text-syle {
        font-size: medium;
        color: #F4C42F;
    }

    .text-paragraph {
        margin: 4px 0;
    }
    .my-name-works {
        font-size: 1.5em;
        font-weight: 600;
        margin: 12px 0 0 20px;
        height: 70px;
    }
    
    .name-mayer {
        border-bottom: solid 7px #171C46;
    }
    .right-container-works {
        display: flex;
        flex-direction: column;
        justify-items: left;
        align-items: center;
        height: 100%;
        position: relative;
        background-color: #171C46;
    }

    .main-content {
        width: 100%;
        padding-bottom: 35px;
    }
    .main-content.is-nav-open {
        display: none;
    }
    .main-empty-1 {
        display:none;
    }
    .main-text {
        display: flex;
        flex-direction: column;
    }
    .main-empty-2 {
        display: none;
    }
    .text-container-works {
        // display: flex;
        // flex-direction: column;
        // width: 100%;
        // padding: 0 20px;
        opacity: 0;
        transform: translateY(-20%);
        transition: opacity 2.5s ease-in;
        transition-delay: .5s;
    }
    .all-works {
        display: flex;
        flex-direction: column;
    }
    .each-work {
        display: inherit;
        flex-direction: column-reverse;
        &:hover { 
            opacity: 0.7;
        }
    }

    .worker-separator {
        height: 3.5px;
        background-color: #D26442;
        margin: 20px;
        margin-top: 30px;
        margin-bottom: 15px;
    }

    .work-image-wrapper {
        margin: 0 20px;
        img {
            height: auto;
            width: 100%;
        }
    }

    .main-work-text {
        font-size:  2em;
        font-weight: 900;
        margin: 10px 0;
        color: white;
        padding: 0 20px;
    }
    .secondary-work-text {
        font-size: 1em;
        color: #F5C509;
        padding: 0 20px;
    }
    .floating-profile-work {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top:12%;
        left: 0;
        right: 0;
        height: 50%;
        z-index: 2
    }
    .no-dec {
        text-decoration: none;
    }
    .no-dec:link {
        color: none;
    }
    .no-dec:active {
        color: white;
    }
    .no-dec:visited {
        color: white;
    }
    .floating-profile-work{
        display: none;
    }
    .my-work { 
        font-size: 1.3em;
        opacity: 0.9;
    }

    .floating-profile-work .profile-image-container {
        position: absolute;
        left: 5%;
        width: 300px;
        height: 300px;
        z-index: 2;
    }
    
    .image-prof {
        width: 100%;
    }
    
    .floating-profile-work .profile-image-blank {
        position: relative;
        background-color: #D26442;
        margin-top: 10%;
        margin-left: 15%;
        height: 325px;
        width: 300px;
        z-index: -1;
        /*box-shadow: 0 2px 4px 0 #000;*/
        border: 3.5px solid #171c46;
    }

    .large-text {
        color:  white;
        font-size: 5em;
        margin: 20px 0;
    }

    .navigation-works {
        left: -100%;
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: #F4C42F;
        width: 100%;
        font-size: 3em;
        height: 100vh;
        z-index: 3;
        transition: left .5s;
        justify-content: center;
    }
    .navigation-works p {
        margin: 10px 0 10px 0;
        .nav-link {
            font-size: 1.2em;
            color: #171C46;
             &:hover {
                color: #fff;
                opacity: 0.7;
             }
        }
    }

    .navigation-works.is-nav-open {
        left: 0;
        padding-left: 20px;

    }
    .nav-icon {
        margin: 1em;
        width: 40px;
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
      }
       
      .nav-icon div {
        background-color: #171C46;
        border-radius: 3px;
        content: '';
        display: block;
        height: 3px;
        margin: 7px 0;
        transition: all .2s ease-in-out;
      }

      .nav-icon.is-nav-open:before {
        transform: translateY(10px) rotate(135deg);
      }
      
      .nav-icon.is-nav-open:after {
        transform: translateY(-10px) rotate(-135deg);
      }
      
      .nav-icon.is-nav-open div {
        transform: scale(0);
      }
      .link-text {
          color: #D26442;
      }



    .work-modal {
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba($color: #7e7e7e, $alpha: 0.6);
        z-index: 4;
        // filter: blur(8px);
        // -webkit-filter: blur(8px);
    }
    .left-modal {
        height: 100vh;  
        width: 100%;
    }
    // .blurred-modal {
    //     position: relative;
    //     width: 20%;
    //     height: 100%;
    //     left: 0;
    //     top: 0;
    //     background: rgba($color: #7e7e7e, $alpha: 0.6);
    //     filter: blur(8px);
    //     -webkit-filter: blur(8px);
    // }
    .modal-main {
        position: fixed;
        right: -100%;
        background: white;
        width: 100%;
        height: 100%;
        top: 0;
        transition: all .5s ease-in-out;
    }
    .modal-main.is-modal-open {
        right:0;
    }

    .close-modal {
        color: #D26442;
        margin: 20px 20px;
        font-size: 1.3em;
        &:hover {
            padding-bottom: 2px;
            border-bottom: 1px solid #D26442;
        }
    }

    .top-content {
        flex-grow: 30;
    }

    .work-details {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        height: 100vh;
    }
     // works section
     .top-content {
        position: fixed;
        color: #D26442;
        /*top: 0px;
        left: -14px;*/
        top: 10px;
        right: 0px;
    }
    

    .first-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #171C46;
        color: white;
    }
    
    .second-content {
        display: flex;
        /*margin: 10px;*/
        font-size: 16px;
        justify-content: space-around;
       /* align-items: center;*/
       p {
            max-width: 500px;
        }
    }
   /* .section-intro {
        width: 300px;
    }*/

    .double-images-wrapper {
        display: flex;
        flex-direction: column;
    }
    .modal-justify-left {
        display: flex;
        flex-direction: column;
    }

    .news-format {
        display: flex;
    }
    .news-format-left {
        margin-left: 10px;
    }
    .news-format-right {
        margin-left: 40px;
        color: #D26442;
    }
    .quoted {
        font-size: 3em;
        position: relative;
        top: 10px;
        color: #D26442;
    }

    .news-sub-heading {
        font-weight: 600;
        margin-top: 0px;
    }
    .news-heading {
        font-weight: 900;
        margin: .5em 0;
    }
    .my-work {
        padding: 0 20px;
        opacity: 0.9;
    }
}

@media screen and (max-width: 800px) {
    .news-format {
        display: flex;
        flex-direction: column;
        position: relative;
        bottom: 20px;
        .with-quote {
            position: relative;
            bottom: 0px;
        }
        .news-format-right {
            margin-left: 0px;
        }
    }
      .modal-justify-left {
        display: flex;
        flex-direction: column;
    }

    .second-content {
        display: flex;
        flex-direction: column;
        p {
            width: 100%;
        }
    }
    .first-content, .second-content, .modal-section {
        padding: 20px;
    }
    .my-name-works {
        font-size: 1.5em;
        font-weight: 600;
        margin: 12px 0 0 20px;
        height: 70px;
    }

    .text-container-works {
        // display: flex;
        // flex-direction: column;
        // width: 100%;
        // padding: 0 20px;
        opacity: 0;
        transform: translateY(-20%);
        transition: opacity 2.5s ease-in;
        transition-delay: .9s;
    }
    .right-container-works {
        .main-content {
        }
    }
    .jbd-header {
        font-size: 1.6em;
    }
    .contains-colored-background {
        padding: 0px;
        .modal-justify-left, .all-blue-background, .left-to-full, .feedback-box  {
            padding: 20px !important;
        }
    }
    .grey-boxes {
        display: flex;
        flex-direction: column;
    }
    .grey-box {
        img {
            width: 100%;
            margin-top: 20px;;
        }
    }
}

.with-quote {
    display: flex;
    position: relative;
    bottom: 25px;
}

.close-modal {
    cursor: pointer;
    font-size: 1.3em;
    &:hover {
        padding-bottom: 2px;
        border-bottom: 1px solid #D26442;
    }
}

.slide-down-works {
    transition: all 2.5s .5s;
    transform: translateY(0);
    opacity: 1;
}

@media only screen and (min-width: 800px) and (max-width: 1199px) {
    .modal-small-img {
        margin-bottom: -5px;
        /*margin-top: 15px;*/
        img {
            width: 450px;
            &.red {
                border: 3px solid #C07676;
            }
            &.green {
                border: 3px solid #8CC177;
            }
        }
    }
    .first-content {
        .main-work-text, .secondary-work-text {
            padding: inherit;
        } 
        .modal-img-one, .modal-img-three {
            img {
                width: 450px;
                padding-left: 15px;
            }
        }
    }
   .first-content, .modal-section, .second-content {
        padding: 40px;
    }
    .news-format-right {
        color: #D26442;
    }
    .news-format-left {
        width: 450px;
    }
    .grey-boxes {
        display: flex;
        justify-content: space-between;
    }
    .grey-box {
        img {
            width: 100%;
        }
    }
    .contains-colored-background {
        padding: 0px;
        .modal-justify-left, .all-blue-background, .left-to-full, .feedback-box  {
            padding: 40px !important;
        }
    }
}
@media only screen and (min-width: 399px) and (max-width: 799px) {
    .first-content {
        display: block;
        .main-work-text, .secondary-work-text {
            padding: inherit;
        } 
    }
    .modal-img-one, .modal-small-img, .modal-img-three {
        img {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 400px) {
    .first-content {
        display: block;
        .main-work-text, .secondary-work-text {
            padding: inherit;
        } 
    }
    .modal-img-one, .modal-small-img, .modal-img-three {
        img {
            width: 100%;
        }
    }
}

// colors
.primary-light-text {
    color: $primary-light;
}
.primary-dark-text {
    color: #171C46;
}
.primary-white-text {
    color: $primary-white;
    margin: 5px 0;
}

.grey {
    background: #fafafa;
}

.basic-session {
    margin: 15px 0;
}

.all-blue-background {
    background-color: #171C46;
}

.middle-section {
    opacity: 0.9;
}
.flex-row {
    display: flex;
}
.align-center {
    align-items: center;
}
.simple-box {
    width: 20px;
    height: 20px;
    background-color: #D26442;
}

.boxed-text {
    margin: 5px 0px 5px 10px;
}
.space-between {
    justify-content: space-between;
}

.main-work-text, .works-type {
    opacity: 0.9;
}

.source a {
    color: #fff;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
.source {
    margin-top: 5px;
    opacity: 0.8;
    font-size: 1.1em;
}

.works-type {
    span {
        font-weight: 600;
    }
}

.with-quote {
    display: flex;
    position: relative;
    bottom: 25px;
}

.close-modal {
    cursor: pointer;
    font-size: 1.3em;
    &:hover {
        padding-bottom: 2px;
        border-bottom: 1px solid #D26442;
    }
}

.slide-down-works {
/*    animation: slide-down 2.5s forwards .5s 1;
*/    transition: all 2.5s .5s;
    transform: translateY(0);
    opacity: 1;
}

.overlap-box-left {
    width: 90%;
    height: 66%;
    position: absolute;
    background-color: #D8D8D8;
}
.overlap-box-right {
    width: 90%;
    height: 66%;
    position: absolute;
    background-color: #D8D8D8;
    bottom: 0;
    right: 0;
    visibility: hidden;
}

.feedback-box {
    background-color: #171C46;
    color: #fff;
    padding: 20px;
    margin-top: 20px;
    opacity: 0.9;
    h3, p {
        opacity: 0.9;
    }
}

.bottom-box-content {
    background-color: #171C46;
    color: #fff;
    padding: 40px;
    .bottom-content {
        opacity: 0.9;
    }
}

.mobileShow {
    display: none;
    @media only screen and (max-width: 450px) {
        display: block;
    }
}

.dot {
    background-color: #FCC300;
    color: #171C46;
    font-weight: 600;
    font-size: 20px;
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 50%;    
    box-sizing: content-box;
    display: inline-block;
    vertical-align: top;
    span {
        position: relative;
        top: 3px;
    }
}

.annotationText {
    display: inline-block;
    width: 85%;
    position: relative;
    bottom: 8px;
    margin-bottom: 15px;
}

.image-prof-works {
    width: 100%;
    
}
.work-text {
    opacity: 0.9
}
.modal-small-img {
    /*margin-bottom: 15px;*/
    /*margin-top: 15px;*/
    img {
        &.red {
            border: 3px solid #C07676;
        }
        &.green {
            border: 3px solid #8CC177;
        }
    }
}
.modal-justify-left {
    .align-center {
        margin-bottom: 15px;
    }
}
.assignments {
    margin-bottom: 25px;
}
.no-top {
    padding-top: 0px !important;
    padding: 40px;
    padding-bottom: 20px;
}
.regular {
    padding: 40px;
    padding-bottom: 20px;
}
.structure {
    margin-bottom: 5px;
    img {
        margin-bottom: 30px;
    }
}
.framework {
    margin-bottom: 35px;
}
.grey-boxes.site-maps {
    flex-direction: column;
    .grey-box {
        margin-bottom: 20px;
    }
}
.full-width {
    width: 100%;
}
.nav-link {
    color: #fff;
    /*opacity: 0.8;*/
    font-size: 1.3rem;
    line-height: 1.3rem;
    text-decoration: none;
}
.sketchingTitles {
    margin-bottom: 15px;
}
.dark-section {
    .flex-row {
        margin-bottom: 15px;
    }
}
.image-prof-works {
    border: 3.5px solid #171c46;
}
.gif-border {
    border: 1px solid #bbb;
}
.wireframes {
    padding-top: 0px
}
.subtitle {
    margin-bottom: 15px;
    display: inline-block;
    &.exercises {
        margin-bottom: 0px;
        margin-top: 25px;
    }
}
.final-section {
    .container {
        margin-bottom: 30px;
        img {
            margin-top: 15px;
        }
    }
}
ul {
    margin-top: 0px;
    padding-left: 25px;
    li {
        margin-bottom: 8px;
    }
}
.finalTransactions {
    .color-box-sections {
        .news-sub-heading {
            margin-bottom: 10px;
            margin-top: 12px 
        }
    }
}
.ideation {
    ol {
        width: 85%;
    }
    img {
        margin-bottom: 5px;
    }
}
.contentContainer {
    margin-bottom: 35px;
}
.middleContainer {
    padding-top: 20px;
    padding-bottom: 20px;
}
.middleContainer.transactions {
    display: block !important;
}
.spaced-section {
    margin-top: 20px;
}
.selectedOccupation {
    margin-top: 20px;
}
.full-queries {
    padding: 40px;
    padding-top: 20px;
}
.metricsText {
    max-width: 500px;
}
h3 {
    margin-top: 0px;
}
@media only screen and (min-width: 1200px) {
    .sunsets {
        /*img  {
            height: 115px;
        }*/
    }
}
.sunsetsWork {
    .extraMargin {
        margin-top: 15px;
    }
    .secondVersion {
        margin-top: 25px;
    }
    .hifiMobile {
        margin-top: 50px;
        margin-bottom: 25px;
    }
    .text-link {
        color: #171C46;
        &:hover {
            text-decoration: none;
        }
    }
    .feedback-box {
        margin-top: 0px;
    }
    .video {
        @media only screen and (max-width: 499px) {
            zoom: 0.19;
        }
        @media screen and (min-width: 500px) and (max-width: 599px) {
            zoom: 0.3;
        }
        @media screen and (min-width: 600px) and (max-width: 699px) {
            zoom: 0.3;
        }
        @media screen and (min-width: 700px) and (max-width: 868px) {
            zoom: 0.3;
        }
        @media screen and (min-width: 868px) and (max-width: 999px) {
            zoom: 0.6;
        }
        @media screen and (min-width: 1000px) and (max-width: 1119px) {
            zoom: 0.45;
        }
        @media screen and (min-width: 1120px) and (max-width: 1199px) {
            zoom: 0.7;
        }
        @media screen and (min-width: 1200px) and (max-width: 1299px) {
            zoom: 0.45;
        }
        @media screen and (min-width: 1300px) and (max-width: 1399px) {
            zoom: 0.7;
        }
        @media screen and (min-width: 1400px) and (max-width: 1499px) {
            zoom: 0.7;
        }
        @media screen and (min-width: 1500px) and (max-width: 1599px) {
            zoom: 0.65;
        }
        @media screen and (min-width: 1600px) and (max-width: 1699px) {
            zoom: 0.6;
        }
        @media screen and (min-width: 1700px) and (max-width: 1799px) {
            zoom: 0.7;
        }
        @media screen and (min-width: 1800px) and (max-width: 1899px) {
            zoom: 0.675;
        }
        @media screen and (min-width: 1900px) and (max-width: 1999px) {
            zoom: 0.7;
        }
        @media screen and (min-width: 2000px) {
            zoom: 0.8;
        }
    }
}