html {
    height: 100%;
}

@font-face {
    font-family: "effra";
    src: url("../fonts/effra/Effra_Std_Bd.ttf") format("ttf"),
    url("../fonts/effra/Effra_Std_Lt.ttf") format("ttf"),
    url("../fonts/effra/Effra_Std_Rg.ttf") format("ttf");
}
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
    font-size: 16px;
    font-family: effra, sans-serif;
}

#root {
    height: 100%;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@import "~react-image-gallery/styles/scss/image-gallery.scss";

.name-mayer {
    color: #181d45;
}

.nav-icon {
    cursor: pointer;
}

/*@media screen and (min-width: 1200px) {
    .main-content {
        height: 100vh;
    }
}*/
